import * as React from "react"
import { graphql } from "gatsby"
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import SEO from "@components/atoms/Seo"
// import { Section } from "@atoms/Section"

import * as css from "@css/layouts/l-error.module.styl"
// import Error404Svg from "@images/Error__img__404.svg"

export default function Error404Page()
{
    return(
        <div>
            <SEO subtitle="404 Not Found." />
            <div className={css.lError}>
                <div className={css.lError__inner}>
                    <h1 className={css.lError__title}>
                        404 Not Found.
                    </h1>
                    <p className={css.lError__text}>お探しのページがありません。</p>
                </div>
            </div>
        </div>
    )
}
